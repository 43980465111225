import { configureStore } from "@reduxjs/toolkit";
import { sidepanelReducer, changeSidePanelStatus, changeBasicsBoxSts } from "./slices/sidepanelSlice";
import { basicsDataApi } from "./apis/basicsDataApi";
import { bagsDataApi } from "./apis/bagsDataApi";
import { activeBagReducer, changeActiveBagId } from "./slices/activeBagSlice";
import { themeReducer, changeThemeStatus, changeIsSystemTheme } from "./slices/themeSlice";

const store = configureStore({
    reducer: {
        // Add Reducers
        sidePanel: sidepanelReducer,
        activeBag: activeBagReducer,
        theme: themeReducer,
        [basicsDataApi.reducerPath]: basicsDataApi.reducer,
        [bagsDataApi.reducerPath]: bagsDataApi.reducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware()
            .concat([
                // Add middlewares
                basicsDataApi.middleware,
                bagsDataApi.middleware,
            ])
    }
})

export { store };

export { sidepanelReducer, changeSidePanelStatus, changeBasicsBoxSts };
export { activeBagReducer, changeActiveBagId };
export { themeReducer, changeThemeStatus, changeIsSystemTheme };

export { useFetchBasicsDataQuery } from './apis/basicsDataApi';
export { useFetchBagsDataQuery } from './apis/bagsDataApi';