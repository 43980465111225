import { createSlice } from "@reduxjs/toolkit";

const activeBagSlice = createSlice({
    name: 'activeBag',
    initialState: {
        id: 1,
    },
    reducers: {
        changeActiveBagId(state, action) {
            return {
                id: action.payload
            }
        }
    }
})

export const { changeActiveBagId } = activeBagSlice.actions;
export const activeBagReducer = activeBagSlice.reducer;