import React from 'react';
import './styles/bag-content.css';
import Dropdown from '../../../components/Dropdown';
import { useSelector } from 'react-redux';
import { useFetchBagsDataQuery } from '../../../store';
import Counter from '../../../components/Counter';

const BagContent = () => {

    const bagsData = useFetchBagsDataQuery();

    const lightTheme = useSelector((state) => {
        return state.theme.status === 'light' ? true : false;
    })

    const activeBagId = useSelector((state) => {
        return state.activeBag.id
    })

    const handleSelectedOptionChange = (option) => {
        console.log("Option change request recieved for new option as " + option.title);
    }

    const handleSelectedDistributorChange = (distributor) => {
        console.log('Distributor change request recieved for new distributor as ' + distributor.title)
    }

    if (bagsData.isLoading) {
        return (
            <div className={`${lightTheme ? 'bag-content-light' : 'bag-content-dark'} bag-content w-full h-full text-base`}>
                <div className='flex flex-col items-center h-full' >
                    <div className={`${lightTheme ? 'bag-content-light' : 'bag-content-dark'} flex flex-row items-center text-2xl h-full`} >
                        Loading
                    </div>
                </div>
            </div>
        )
    } else if (bagsData.error) {
        return (
            <div className={`${lightTheme ? 'bag-content-light' : 'bag-content-dark'} bag-content w-full h-full text-base`}>
                <div className='flex flex-col items-center h-full' >
                    <div className={`${lightTheme ? 'bag-content-light' : 'bag-content-dark'} flex flex-row items-center text-2xl h-full`} >
                        There has been an error, please excuse the poor developers :(
                    </div>
                </div>
            </div>
        )
    } else {

        const renderItems = bagsData.data.map((bag) => {
            if (bag.id === activeBagId) {
                return bag.items.map((item, i) => {
                    return (
                        <div key={item.id} className={
                            `
                                    ${lightTheme ? 'items-list-cards-light' : 'items-list-cards-dark'}
                                    ${i === 0 ? lightTheme ? 'items-list-top-card-light' : 'items-list-top-card-dark' : ''}
                                    items-list-cards flex flex-row gap-4 px-4 py-2 items-center
                            `
                        } >
                            <div className='bag-list-col-select-base text-center'>
                                <input type='checkbox' />
                            </div>
                            <div className='bag-list-col-slno-base text-center'>{i + 1 + '.'}</div>
                            <div className='bag-list-col-id-base text-center'>{item.id}</div>
                            <div className='bag-list-col-name-base'>{item.name}</div>
                            <div className='bag-list-col-composition-base'>{item.composition}</div>
                            <div className='bag-list-col-pack-size-base flex flex-col items-center' >
                                <div className={`${lightTheme ? 'bag-list-selectors-light' : 'bag-list-selectors-dark'} bag-list-pack-size-selector h-full px-1`} >
                                    <Dropdown
                                        options={item.packSizes}
                                        selectedOptionId={item.selectedPackSize}
                                        bgColor={lightTheme ? '#fafafa' : '#101010'}
                                        color={lightTheme ? '#303030' : '#e8e8e3'}
                                        onSelectedOptionChange={handleSelectedOptionChange}
                                    />
                                </div>
                            </div>
                            <div className='bag-list-col-qnt-base flex flex-col items-center' >
                                <div className='bag-list-qnt-parent' >
                                    <Counter
                                        bgColors={
                                            lightTheme ?
                                                { negBtn: '#ededed', display: '#fafafa', posBtn: '#ededed' }
                                                : { negBtn: '#181818', display: '#101010', posBtn: '#181818' }
                                        }
                                        colors={
                                            lightTheme ?
                                                { negBtn: '#303030', display: '#202020', posBtn: '#303030' }
                                                : { negBtn: '#e9e9e9', display: '#e9e9e9', posBtn: '#e9e9e9' }
                                        }
                                        borders={
                                            lightTheme ?
                                                { body: '1px solid #bebebe', negBtn: '1px solid #bebebe', posBtn: '1px solid #bebebe' }
                                                : { body: '1px solid #454545', negBtn: '1px solid #454545', posBtn: '1px solid #454545' }
                                        }
                                        initialCount={1}
                                    />
                                </div>
                            </div>
                            <div className='bag-list-col-price-base'>{'₹' + String(item.mrp.rupees) + '.' + String(item.mrp.paisa)}</div>
                            <div className='bag-list-col-select-distributor-base flex flex-col items-center' >
                                <div className={`${lightTheme ? 'bag-list-selectors-light' : 'bag-list-selectors-dark'} bag-list-select-distributor-selector px-1`} >
                                    <Dropdown
                                        options={item.distributors}
                                        selectedOptionId={item.selectedDistributorId}
                                        bgColor={lightTheme ? '#fafafa' : '#101010'}
                                        color={lightTheme ? '#303030' : '#e8e8e3'}
                                        onSelectedOptionChange={handleSelectedDistributorChange}
                                    />
                                </div>
                            </div>
                        </div >
                    )
                });
            } else {
                return null;
            }
        })

        return (
            <div className={`${lightTheme ? 'bag-content-light' : 'bag-content-dark'} bag-content w-full h-full text-base`}>
                <div className='bag-list-col-header flex flex-row gap-4'>
                    <div className='bag-list-col-select-base ml-4 text-center' >S</div>
                    <div className='bag-list-col-slno-base text-center' >SL No.</div>
                    <div className='bag-list-col-id-base text-center' >ID</div>
                    <div className='bag-list-col-name-base' >Name</div>
                    <div className='bag-list-col-composition-base'>Composition</div>
                    <div className='bag-list-col-pack-size-base text-center'>Pack Size</div>
                    <div className='bag-list-col-qnt-base text-center'>Quantity</div>
                    <div className='bag-list-col-price-base text-center'>Price</div>
                    <div className='bag-list-col-select-distributor-base text-center'>Select Distributor</div>
                </div>
                <div className='flex flex-col mt-5'  >
                    {renderItems}
                </div>
            </div>
        )
    }
}

export default BagContent;