import React from 'react';
import './styles/search-panel.css'

// Icon Imports 
import { AiOutlineSearch } from 'react-icons/ai';
import { FaFilter } from 'react-icons/fa';
import { BiSort } from 'react-icons/bi';
import { useSelector } from 'react-redux';

const SearchPanel = () => {


    const lightTheme = useSelector((state) => {
        return state.theme.status === 'light' ? true : false;
    })


    // Filter & Sort Functions ---------------------------------------------------------------------------------------------
    const handleFilterBtnClick = () => {                                                                                    //
        alert("Filter & Sort Options are not yet developed. Thank you for testing it!");                                    //
    };                                                                                                                      //
    //                                                                                                                      //
    const handleSortBtnClick = () => {                                                                                      //
        alert("Sort Options are not yet developed. It's awesome that you tested it!");                                      //
    };                                                                                                                      //
    //                                                                                                                      //
    const handleResetBtnClick = () => {                                                                                     //  
        alert("Filter & Sort Options are not yet developed. Thank you for testing it!");                                    //  
    };                                                                                                                      //
    //                                                                                                                      //
    const handleSearchBtnClick = () => {                                                                                    //  
        alert("Search Functionality is not yet developed. Kudos 🏅 you for testing. Test more interesting people 🤩 !")    //
    }                                                                                                                       //
    //                                                                                                                      //
    // ---------------------------------------------------------------------------------------------------------------------
    return (
        <div className='flex flex-col items-start gap-1' >
            <span className={`${lightTheme ? 'search-label-light' : 'search-label-dark'} text-base font-base`} >
                Add a new item
            </span>
            <div className={`flex flex-row items-center justify-between w-full gap-4`} >
                <div className='flex-auto' >
                    <div className={`${lightTheme ? 'bag-header-search-box-light' : 'bag-header-search-box-dark'} bag-header-search-box flex flex-row items-center`} >
                        <input placeholder='Search' className='h-8 outline-none w-full m-1 pl-3' ></input>
                        <div onClick={handleSearchBtnClick} className='px-2 mr-1 text-xl cursor-pointer' >
                            <AiOutlineSearch />
                        </div>
                    </div>
                </div>
                <div className={`${lightTheme ? 'bag-header-search-btns-box-light' : 'bag-header-search-btns-box-dark'} bag-header-search-btns-box w-fit flex flex-row items-center gap-2 mr-1`} >
                    <button onClick={handleFilterBtnClick} className={`${lightTheme ? 'filter-and-sort-btn-light' : 'filter-and-sort-btn-dark'} filter-and-sort-btn flex flex-row items-center gap-1 px-4 py-2`} >
                        <div className='text-lg cursor-pointer' >
                            <FaFilter />
                        </div>
                        <label className='cursor-pointer' >Filter</label>
                    </button>
                    <button onClick={handleSortBtnClick} className={`${lightTheme ? 'filter-and-sort-btn-light' : 'filter-and-sort-btn-dark'} filter-and-sort-btn flex flex-row items-center gap-1 px-4 py-2`}>
                        <div className='text-lg cursor-pointer' >
                            <BiSort />
                        </div>
                        <label className='cursor-pointer' >Sort</label>
                    </button>
                    <button onClick={handleResetBtnClick} className={`${lightTheme ? 'filter-and-sort-reset-btn-light' : 'filter-and-sort-reset-btn-dark'} filter-and-sort-reset-btn text-white flex flex-row items-center gap-1 px-6 py-2`}>
                        <label className='cursor-pointer' >Reset</label>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SearchPanel