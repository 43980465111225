import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";

const bagsDataApi = createApi({
    reducerPath: "bagsData",
    baseQuery: fetchBaseQuery({
        // baseUrl: "http://localhost:3003"
        baseUrl: "https://little-boy.onrender.com"
    }),
    endpoints(builder) {
        return {
            fetchBagsData: builder.query({
                query: () => {
                    return {
                        url: "/bagsData",
                        method: 'GET'
                    }
                }
            })
        }
    }
})

export const { useFetchBagsDataQuery } = bagsDataApi;
export { bagsDataApi };