import React from 'react';
import { changeBasicsBoxSts, changeActiveBagId } from '../../store';
import { useFetchBagsDataQuery, useFetchBasicsDataQuery } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import './styles/sidepanel.css';
import './styles/sidepanel-light.css';
import './styles/sidepanel-dark.css';

// import icons
import { IoIosArrowDropup, IoIosArrowDropdown } from 'react-icons/io';
import { AiFillMedicineBox, AiOutlineMedicineBox } from 'react-icons/ai';
import { MdModeEdit } from 'react-icons/md';

const SidePanel = () => {


    const dispatch = useDispatch();

    // activeBagId is a int value that tells the id of the active bag in the dashboard
    const activeBagId = useSelector((state) => {
        return state.activeBag.id;
    })

    // basicsBoxSts is a boolean value, that tells the status (expanded as true and shrinked as false) of the profile (basics) section in the sidepanel.
    const { basicsBoxSts } = useSelector((state) => {
        return state.sidePanel;
    })

    const themeSts = useSelector((state) => {
        return state.theme.status;
    })

    const lightTheme = themeSts === 'light' ? true : false;

    // basicsData and bagsData are data used in profile(basics) section and bags section corresponsingly. These values are being fetched from a backend server using the react-redux-toolkit-query apis.
    const basicsData = useFetchBasicsDataQuery();
    const bagsData = useFetchBagsDataQuery();


    const handleBasicsBoxToggle = () => {
        dispatch(changeBasicsBoxSts(!basicsBoxSts));
    }

    const handleBagClick = (id) => {
        dispatch(changeActiveBagId(id));
    }

    const handleAddBagsBtnClick = () => {
        alert("Mechanism for adding new bags is not developed yet. Kudos you for testing 🥳. Stay Tuned & test more Folkes!");
    }

    const handleBagBtnEditBtnClick = (id) => {
        const bagName = bagsData.data.map((bag) => {
            if (bag.id === id) {
                return bag.title;
            } else {
                return null;
            }
        })
        alert(`If I am not wrong then, you are trying to change the bag details of the bag named "${bagName}", but the edit functionalities are not yet developed. But kudos 🏅 for testing this though!`)
    }

    // let variable is declared here to make it global in this component. This variable then is assigned with values in conditionals below.
    let renderBags;

    if (!bagsData.isError && !bagsData.isLoading) {
        renderBags = bagsData.data.map((bag, i) => {
            return (
                <button
                    key={bag.id}
                    className={
                        `
                                ${i === 0 && bag.id !== activeBagId ?
                            'border-top-0p5-333'
                            : 'border-top-0p5-transparent'
                        }

                                ${i === bagsData.data.length - 1 && bag.id !== activeBagId
                        && 'border-bottom-0p5-333'
                        }

                                ${bag.id === activeBagId ?
                            lightTheme ? 'active-bag active-bag-light'
                                : 'active-bag active-bag-dark'
                            : lightTheme ?
                                'inactive-bag-light'
                                : 'inactive-bag-dark'

                        }

                                ${bag.id + 1 === activeBagId ?
                            'border-bottom-0p5-transparent'
                            : bag.id !== activeBagId ?
                                'border-bottom-0p5-333'
                                : 'border-bottom-0p5-transparent'
                        }

                                ${bag.id - 1 === activeBagId ?
                            'border-bottom-0p5-333'
                            : 'border-bottom-0p5-transparent'
                        }
                    `
                    }
                    onClick={() => handleBagClick(bag.id)}
                >
                    <div className='flex flex-row items-center justify-between'>
                        <div className='flex flex-row items-center gap-3' >
                            <div className='text-2xl' title={bag.title} >
                                {
                                    lightTheme ?
                                        <AiOutlineMedicineBox />
                                        : <AiFillMedicineBox />
                                }
                            </div>
                            <div >
                                {bag.title}
                            </div>
                        </div>
                        <div onClick={() => handleBagBtnEditBtnClick(bag.id)} className='cursor-pointer' title="Edit bag">
                            <MdModeEdit />
                        </div>
                    </div>
                </button>
            )
        })
    }

    return (
        <div className={`side-panel ${lightTheme ? 'side-panel-light' : 'side-panel-dark'} bg-white`}>
            <div className='h-screen' >
                <div className={`${lightTheme ? 'basics-box-toggle-light' : 'basics-box-toggle-dark'} py-4 flex flex-row justify-between w-full px-4`} >
                    <div className='w-1'>
                        {
                            !basicsBoxSts &&
                            <label className='text-base font-semibold select-none' >Profile</label>
                        }
                    </div>
                    <div className='text-xl cursor-pointer' onClick={handleBasicsBoxToggle}>
                        {
                            basicsBoxSts ?
                                <IoIosArrowDropup />
                                : <IoIosArrowDropdown />
                        }
                    </div>
                </div>
                <div className={`basics-box-parent`}>
                    {
                        basicsBoxSts &&
                        <div id='basics-box' className={`${lightTheme ? 'basics-box-light' : 'basics-box-dark'} basics-box flex flex-col items-center gap-5 py-4`}>
                            {
                                basicsData.isLoading ?
                                    <div className='text-2xl pb-12 select-none' >
                                        Loading
                                    </div>
                                    :
                                    basicsData.error ?
                                        <div className='text-xl pb-12' >
                                            Some Error Occured :(
                                        </div>
                                        :
                                        <div className='sub-basics-box w-full flex flex-col gap-4 mb-2'>
                                            <div className='img-and-storename-box flex flex-col items-center gap-4 '>
                                                <div className='flex flex-col items-center'>
                                                    <div className='image-box select-none'>
                                                        <img src={basicsData.data.avatar} alt='store dp' />
                                                    </div>
                                                    <div className='storename-box text-xl font-semibold '>
                                                        <h2>{basicsData.data.name}</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='basics-btns-box flex flex-col w-full gap-2 select-none' >
                                                <button>Contributions</button>
                                                <button>Networks & Connections</button>
                                                <button>Orders & Responses</button>
                                            </div>
                                        </div>
                            }
                        </div>
                    }
                </div>
                <div className={`${lightTheme ? 'bags-box-light' : 'bags-box-dark'} bags-box  py-4 flex flex-col gap-2 `}>
                    <div className='flex flex-row items-center justify-between mx-4 mb-2' >
                        <label className='text-base font-semibold select-none' >Bags</label>
                        <button onClick={handleAddBagsBtnClick} className={`${lightTheme ? 'add-bags-btn-light' : 'add-bags-btn-dark'} add-bags-btn px-3 py-2 select-none`} >{"Add Bag +"}</button>
                    </div>
                    <div className='bags-btns-box flex flex-col items-center w-full' >
                        {
                            bagsData.isLoading ?
                                <div className='text-xl pb-12 select-none'>
                                    Loading
                                </div>
                                :
                                bagsData.error ?
                                    <div className='text-xl pb-12'>
                                        Some Error Occured :(
                                    </div>
                                    :
                                    <div className='bags-btns-subbox flex flex-col w-full select-none'>
                                        {renderBags}
                                    </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SidePanel;