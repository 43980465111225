import { useDispatch, useSelector } from "react-redux";
import Navbar from "./features/navbar";
import SidePanel from "./features/sidepanel";
import './app.css';
import BagOverviewPane from "./features/BagOverviewPane";
import useThemeDetector from "./hooks/useThemeDetector";
import { useEffect } from "react";
import { changeThemeStatus } from "./store";

function App() {

  const dispatch = useDispatch();

  const sidePanelSts = useSelector((state) => {
    return state.sidePanel.status;
  })

  const lightTheme = useSelector((state) => {
    return state.theme.status === 'light' ? true : false;
  })

  const { isSystemTheme } = useSelector((state) => {
    return state.theme
  })

  const isDark = useThemeDetector();

  useEffect(() => {
    isSystemTheme &&
      dispatch(changeThemeStatus(isDark ? 'dark' : 'light'));
  }, [isDark])

  return (
    <div className={`app ${lightTheme ? 'app-light' : 'app-dark'} `}>
      <div className="w-full h-14" />
      <div className="flex flex-row">
        <div className="sidepanel-parent" >
          {
            sidePanelSts && <SidePanel />
          }
        </div>
        <div className="bodypanel-parent flex-1" >
          {/* Add other panels & sections here */}
          <BagOverviewPane />
        </div>
      </div>
      <div>
        <Navbar />
      </div>
    </div>
  );
}

export default App;