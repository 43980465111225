import { createSlice } from "@reduxjs/toolkit";

const themeSlice = createSlice({
    name: 'themeslice',
    initialState: {
        // states --> light, dark
        status: 'light',
        isSystemTheme: true
    },
    reducers: {
        changeThemeStatus(state, action) {
            return {
                ...state,
                status: action.payload
            }
        },
        changeIsSystemTheme(state, action) {
            return {
                ...state,
                isSystemTheme: action.payload === true ?
                    true :
                    action.payload === false ?
                        false :
                        state.isSystemTheme
            }
        }
    }
})

export const { changeThemeStatus, changeIsSystemTheme } = themeSlice.actions;
export const themeReducer = themeSlice.reducer;