import React from 'react';
import './style/bag-overview.css'
import BagHeader from './components/BagHeader';
import BagContent from './components/BagContent';
import { useSelector } from 'react-redux';

const BagOverviewPane = () => {

    const lightTheme = useSelector((state) => {
        return state.theme.status === 'light' ? true : false;
    })

    return (
        <div className={`bag-overview-pane ${lightTheme ? 'bag-overview-pane-light' : 'bag-overview-pane-dark'} `} >
            <div className='flex flex-row items-start h-full' >
                <div className='flex flex-col items-center w-full h-full' >
                    <div className={`bag-header-parent ${lightTheme ? 'bag-header-parent-light' : 'bag-header-parent-dark'} w-full p-4 pb-8`} >
                        <BagHeader />
                    </div>
                    <div className={`${lightTheme ? 'bag-content-parent-light' : 'bag-content-parent-dark'} bag-content-parent w-full flex-1 p-4`} >
                        <BagContent />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BagOverviewPane;