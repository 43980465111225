import React, { useState } from 'react';
import './styles/bag-header.css'
import { useFetchBagsDataQuery } from '../../../store';
import { useSelector } from 'react-redux';

// Icon Imports
import { BiEdit, BiSolidEdit } from 'react-icons/bi'
import SearchPanel from './SearchPanel';

const BagHeader = () => {

    const [titleEditHover, setTitleEditHover] = useState(false);

    const bagsData = useFetchBagsDataQuery();

    const activeBagId = useSelector((state) => {
        return state.activeBag.id;
    })

    const lightTheme = useSelector((state) => {
        return state.theme.status === 'light' ? true : false;
    })


    // Functions

    const handleTitleEditBtnMouseEnter = () => {
        setTitleEditHover(true);
    }

    const handleTitleEditBtnMouseLeave = () => {
        setTitleEditHover(false);
    }

    const handleTitleEditBtnClick = () => {
        alert("The edit feature is not yet developed. Stay tuned!");
    }

    const handleEditDescriptionSpanClick = () => {
        alert("The edit feature is not yet developed. Stay tuned!");
    }

    if (bagsData.isLoading) {
        return <div className={`bag-header w-full h-full`}>
            <div className='flex flex-col items-center h-full' >
                <div className={`${lightTheme ? 'bag-header-title-light' : 'bag-header-title-dark'} flex flex-row items-center text-2xl h-full`} >
                    Loading
                </div>
            </div>
        </div>
    } else if (bagsData.error) {
        return <div className={`bag-header w-full h-full`}>
            <div className='flex flex-col items-center h-full' >
                <div className={`${lightTheme ? 'bag-header-title-light' : 'bag-header-title-dark'} flex flex-row items-center text-2xl h-full`} >
                    There has been an error, please excuse the poor developers :(
                </div>
            </div>
        </div>
    } else {

        const renderBagsDetails = bagsData.data.map((bag) => {
            if (bag.id === activeBagId) {
                return (
                    <div key={bag.id} >
                        <div className={`${lightTheme ? 'bag-header-title-light' : 'bag-header-title-dark'} flex flex-row items-center justify-between`} >
                            <h1 className='text-3xl pb-1 font-base'>{bag.title}</h1>
                            <div className='cursor-pointer p-1 text-2xl' onMouseEnter={handleTitleEditBtnMouseEnter} onMouseLeave={handleTitleEditBtnMouseLeave} onClick={handleTitleEditBtnClick}>
                                {
                                    titleEditHover ?
                                        <BiSolidEdit />
                                        : <BiEdit />
                                }
                            </div>
                        </div>
                        <div className={`${lightTheme ? 'bag-header-description-light' : 'bag-header-description-dark'} text-sm pb-4 w-4/5`} >
                            {
                                bag.description ?
                                    bag.description
                                    :
                                    <div className='flex flex-row gap-1' >
                                        <span className='italic text-gray-500 ml-1'>
                                            No Description.
                                        </span>
                                        <span onClick={handleEditDescriptionSpanClick} className='italic text-blue-400 cursor-pointer underline-on-hover' >Edit to add description</span>
                                    </div>
                            }
                        </div>
                    </div>
                )
            } else {
                return null;
            }
        })

        return (
            <div className={`bag-header flex flex-col w-full h-full`} >
                <div>
                    {renderBagsDetails}
                </div>
                <div>
                    <SearchPanel />
                </div>
            </div>
        )
    }
}

export default BagHeader;