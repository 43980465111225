import React, { useState } from 'react'

const Counter = ({ bgColors, colors, borders, onCountChange, initialCount }) => {
    // temporary counter mechanism for showcasing prototype only
    const [count, setCount] = useState(1);

    return (
        <div className='counter-root flex flex-row h-full w-full' style={{ border: borders.body }} >
            <div
                className='counter-neg-btn cursor-pointer flex-1 flex flex-row items-center text-center h-full select-none'
                style={{ backgroundColor: bgColors.negBtn, color: colors.negBtn, borderRight: borders.negBtn }}
                onClick={() => setCount(count - 1)}
            >
                <div className='w-full flex flex-col items-center' >
                    -
                </div>
            </div>
            <div
                className='counter-display flex-1 flex flex-row items-center h-full'
                style={{ backgroundColor: bgColors.display, color: colors.display }}
            >
                <div className='w-full flex flex-col items-center'>
                    <input className='w-full outline-none bg-transparent text-center' value={count} />
                </div>
            </div>
            <div
                className='counter-pos-btn cursor-pointer flex-1 flex flex-row items-center h-full select-none'
                style={{ backgroundColor: bgColors.posBtn, color: colors.posBtn, borderLeft: borders.posBtn }}
                onClick={() => setCount(count + 1)}
            >
                <div className='w-full flex flex-col items-center' >
                    +
                </div>
            </div>
        </div>
    )
}

export default Counter;