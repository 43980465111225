import { createSlice } from "@reduxjs/toolkit";

const sidepanelSlice = createSlice({
    name: 'sidepanel',
    initialState: {
        status: true,
        basicsBoxSts: true
    },
    reducers: {
        changeSidePanelStatus(state, action) {
            return {
                status: action.payload,
                basicsBoxSts: state.basicsBoxSts
            }
        },
        changeBasicsBoxSts(state, action) {
            return {
                status: state.status,
                basicsBoxSts: action.payload
            }
        }
    }
})

export const { changeSidePanelStatus, changeBasicsBoxSts } = sidepanelSlice.actions;
export const sidepanelReducer = sidepanelSlice.reducer;