import React from 'react';


const Dropdown = ({ options, selectedOptionId, bgColor, color, onSelectedOptionChange }) => {

    const renderSelectedOption = options.map((option) => {
        if (option.id === selectedOptionId) {
            return <option key={option.id} title={option.details ? option.details : ''}>
                {option.title}
            </option>
        } else {
            return null;
        }
    })

    const renderOptions = options.map((option) => {
        if (option.id === selectedOptionId) {
            return null;
        } else {
            return (
                <option key={option.id} title={option.details ? option.details : ''}>{option.title}</option>
            )
        }
    })

    return (
        <div className='bg-blue-300 w-full h-full'>
            <div className='bg-gray-700 h-full w-full' >
                <select className='w-full h-full text-center outline-none select-none' style={{ backgroundColor: bgColor, color: color }} >
                    {renderSelectedOption}
                    {renderOptions}
                </select>
            </div>
        </div >
    )
}

export default Dropdown;