import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeIsSystemTheme, changeSidePanelStatus, changeThemeStatus } from '../../store';
import './styles/navbar.css';

// icons
import { TfiMenu } from 'react-icons/tfi';
import { BiSolidMessageDetail } from 'react-icons/bi';
import { FiSettings } from 'react-icons/fi';
import { TbLayoutSidebarLeftCollapseFilled, TbLayoutSidebarRightCollapseFilled } from 'react-icons/tb';
import { ImBrightnessContrast } from 'react-icons/im';
import { MdBrightness4 } from 'react-icons/md';

const Navbar = () => {

    const dispatch = useDispatch();

    const sidePanelSts = useSelector((state) => {
        return state.sidePanel.status;
    })

    const themeSts = useSelector((state) => {
        return state.theme.status;

    })

    // Functions

    const handleThemeSwitchBtnClick = () => {
        let newTheme = themeSts === 'light' ? 'dark' : 'light';
        dispatch(changeThemeStatus(newTheme));
        dispatch(changeIsSystemTheme(false))
    }

    const handleMessageBtnClick = () => {
        alert("We haven't built the message features yet. Thank you for testing this out though.");
    }

    const handleSettingsBtnClick = () => {
        alert("We are barely seeding the app right now, settings is further away. But Kudos 🏅 for testing 🤩");
    }

    return (
        <div className={`${themeSts === 'light' ? 'navbar-light' : 'navbar-dark'} fixed top-0 left-0 w-full h-14 flex flex-row items-center justify-between px-8`}>
            <div className='side-bar-btn text-3xl cursor-pointer' onClick={() => dispatch(changeSidePanelStatus(!sidePanelSts))}  >
                {
                    sidePanelSts ?
                        <TbLayoutSidebarLeftCollapseFilled />
                        : <TfiMenu />
                }
            </div>
            <div className='navbar-branding text-3xl select-none' >
                Project Vishnu 🦚
            </div>
            <div className='navbar-btns flex flex-row items-center gap-4'>
                <div onClick={handleThemeSwitchBtnClick} className='text-2xl cursor-pointer' >
                    {
                        themeSts === 'light' ?
                            <ImBrightnessContrast />
                            : <MdBrightness4 />
                    }
                </div>
                <div onClick={handleMessageBtnClick} className='cursor-pointer text-2xl mt-1'>
                    <BiSolidMessageDetail />
                </div>
                <div onClick={handleSettingsBtnClick} className='cursor-pointer text-2xl' >
                    <FiSettings />
                </div>
            </div>
        </div>
    )
}

export default Navbar;