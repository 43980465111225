import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";

const basicsDataApi = createApi({
    reducerPath: "basicsData",
    baseQuery: fetchBaseQuery({
        // baseUrl: "http://localhost:3003"
        baseUrl: "https://little-boy.onrender.com"
    }),
    endpoints(builder) {
        return {
            fetchBasicsData: builder.query({
                query: () => {
                    return {
                        url: "/basicsData",
                        method: "GET"
                    }
                }
            })
        }
    }
})

export const { useFetchBasicsDataQuery } = basicsDataApi;
export { basicsDataApi };